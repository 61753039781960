<template>
  <el-container>
    <el-main>
          <el-tabs value="first" style="outline: 0;">
            <el-tab-pane label="编辑组信息" name="first">
              <el-form ref="groupRef" :rules="rules" :model="groupForm" label-width="100px" size="small">
                <el-form-item label="组代码" prop="groupCode">
                  <el-input placeholder="组代码" v-model="groupForm.groupCode" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="组名称" prop="groupName">
                  <el-input placeholder="组名称" v-model="groupForm.groupName" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="是否启用">
                  <el-switch v-model="groupForm.isEnabled" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="描述" prop="groupDesc">
                  <el-input type="textarea" v-model="groupForm.groupDesc"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit('groupRef')">立即编辑</el-button>
                  <el-button @click="drawer.status = false">取消</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  props: {
    drawer: Object,
    findByPage: Function
  },
  data() {
    return {
      groupForm: {
        id: undefined,
        version: '',
        deleted: '',
        groupName: '',
        groupCode: '',
        groupDesc: '',
        isEnabled: '1',
        groupRoleList: []
      },
      rules: {
        groupName: [
          {required: true, message: '请填写组名称', trigger: 'blur'}
        ],
        groupCode: [
          {required: true, message: '请填写组代码', trigger: 'blur'}
        ],
        groupDesc: [
          {required: true, message: '请填写组描述', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.role.pageSize = val
      this.findRoleByPage()
    },
    handleCurrentChange(val) {
      this.role.currentPage = val
      this.findRoleByPage()
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            method: 'post',
            url: '/masterdata/sysGroup/updateSysGroup',
            contentType: 'application/json',
            data: this.groupForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            // eslint-disable-next-line no-empty
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '编辑成功',
                type: 'success',
              });

              this.$refs[formName].resetFields();
              this.drawer.status = false
              this.findByPage()
            }
          })
        }
      })
    },
    formatIsEnabled: function (row) {
      return row.isRoleEnabled === 1 ? '是' : row.isRoleEnabled === 0 ? '否' : '未知'
    },
  }, created() {
    this.groupForm = Object.assign({}, this.drawer.groupData)
  }, mounted() {
    this.role.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 230;

  }
}
</script>

<style scoped>
/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.el-footer {
  /*background-color: #B3C0D1;*/
  color: #333;
  text-align: right;
}

.el-aside {
  color: #333;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-main {
  color: #333;
}

.el-container {
  /*padding: 20px;*/
  height: 100% !important;
}

.el-tree-node__content {
  height: 30px !important;
}
</style>
