<template>
  <el-container>
    <el-aside width="45%">
      <el-tabs value="first" style="outline: 0;">
        <el-tab-pane label="未关联角色" name="first">
          <el-table
              :data="roleNotInGroup.tableData"
              :height="tableHeight"
              border
              size="small"
              style="width: 100%;margin-top: 10px;"
              v-loading="roleNotInGroup.loading"
              ref="topictable"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="id"
                width="50">
            </el-table-column>
            <el-table-column
                prop="roleCode"
                label="角色代码"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="roleName"
                label="角色名称"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="isRoleEnabled"
                label="是否激活"
                :formatter="formatIsEnabled"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="createdTime"
                label="创建时间"
                show-overflow-tooltip>
            </el-table-column>
          </el-table>

          <el-pagination
              @size-change="(val) => handleSizeChange('roleNotInGroup',val)"
              @current-change="(val) => handleCurrentChange('roleNotInGroup',val)"
              :current-page="roleNotInGroup.currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="roleNotInGroup.pageSize"
              :pager-count="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="roleNotInGroup.pageTotal"
              style="margin-top: 10px">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-aside>
    <div style="width: 10%;display: block;line-height: 500px;text-align: center">
      <el-button type="primary" @click="removeGroupRole" icon="el-icon-caret-left" size="small"></el-button>
      <el-button type="primary" @click="insertGroupRole" icon="el-icon-caret-right" size="small"></el-button>
    </div>
    <el-main width="45%" style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
      <el-tabs value="first" style="outline: 0;">
        <el-tab-pane label="已关联角色" name="first">
          <el-table
              :data="roleInGroup.tableData"
              :height="tableHeight"
              border
              size="small"
              style="width: 100%;margin-top: 10px;"
              v-loading="roleInGroup.loading"
              ref="topictable1"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="id"
                width="50">
            </el-table-column>
            <el-table-column
                prop="roleCode"
                label="角色代码"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="roleName"
                label="角色名称"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="isRoleEnabled"
                label="是否激活"
                :formatter="formatIsEnabled"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="createdTime"
                label="创建时间"
                show-overflow-tooltip>
            </el-table-column>
          </el-table>

          <el-pagination
              @size-change="(val) => handleSizeChange('roleInGroup',val)"
              @current-change="(val) => handleCurrentChange('roleInGroup',val)"
              :current-page="roleInGroup.currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="roleInGroup.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="roleInGroup.pageTotal"
              style="margin-top: 10px">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  props: {
    transfer: Object,
    findByPage: Function
  },
  data() {
    return {
      tableHeight: null,
      roleInGroup: {
        tableData: [],
        multipleSelection: [],
        currentPage: 1,
        pageSize: 10,
        loading: true,
        pageTotal: 0,
      },
      roleNotInGroup: {
        tableData: [],
        multipleSelection: [],
        currentPage: 1,
        pageSize: 10,
        loading: true,
        pageTotal: 0,
      },
      groupRoleList: []
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(tableName, val) {
      if (tableName === 'roleNotInGroup') {
        this.roleNotInGroup.pageSize = val
        this.findRoleNotInGroup()
      } else {
        this.roleInGroup.pageSize = val
        this.findRoleInGroup()
      }
    },
    handleCurrentChange(tableName, val) {
      if (tableName === 'roleNotInGroup') {
        this.roleNotInGroup.currentPage = val
        this.findRoleNotInGroup()
      } else {
        this.roleInGroup.currentPage = val
        this.findRoleInGroup()
      }
    },
    formatIsEnabled: function (row) {
      return row.isRoleEnabled === 1 ? '是' : row.isRoleEnabled === 0 ? '否' : '未知'
    },
    findRoleInGroup() {
      this.roleInGroup.loading = true

      return request({
        url: `/masterdata/sysRoleGroup/findRoleGroup/1/${this.transfer.groupId}/${this.roleInGroup.currentPage}/${this.roleInGroup.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: {}
      }).then((response) => {
        this.roleInGroup.tableData = response.data.content;
        this.roleInGroup.pageTotal = response.data.totalElements;
        this.roleInGroup.loading = false;
      })
    },
    findRoleNotInGroup() {
      this.roleNotInGroup.loading = true

      return request({
        url: `/masterdata/sysRoleGroup/findRoleGroup/2/${this.transfer.groupId}/${this.roleNotInGroup.currentPage}/${this.roleNotInGroup.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: {}
      }).then((response) => {
        this.roleNotInGroup.tableData = response.data.content;
        this.roleNotInGroup.pageTotal = response.data.totalElements;
        this.roleNotInGroup.loading = false;
      })
    },
    insertGroupRole() {
      this.groupRoleList = []

      const rows = this.$refs.topictable.store.states.selection

      rows.forEach(row => {
        this.groupRoleList.push({'roleId': row.id, 'groupId': this.transfer.groupId})
      })

      return request({
        url: `/masterdata/sysRoleGroup/createSysRoleGroup`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: this.groupRoleList
      }).then((response) => {
        if (response.code === 200) {
          this.$message({
            showClose: true,
            message: response.message,
            type: 'success',
          });

          this.findRoleInGroup()
          this.findRoleNotInGroup()
        }
      })
    },
    removeGroupRole() {
      this.groupRoleList = []

      const rows = this.$refs.topictable1.store.states.selection

      rows.forEach(row => {
        this.groupRoleList.push({'roleId': row.id, 'groupId': this.transfer.groupId})
      })

      return request({
        url: `/masterdata/sysRoleGroup/deleteSysRoleGroup`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: this.groupRoleList
      }).then((response) => {
        if (response.code === 200) {
          this.$message({
            showClose: true,
            message: response.message,
            type: 'success',
          });

          this.findRoleInGroup()
          this.findRoleNotInGroup()
        }
      })
    }
  }, created() {
    this.findRoleInGroup()
    this.findRoleNotInGroup()
  }, mounted() {
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 460;
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
  padding: 0 !important;
}

.el-aside {
  color: #333;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-main {
  color: #333;
  padding: 5px !important;
}

.el-container {
  /*padding: 20px;*/
  height: 100% !important;
}

.el-tree-node__content {
  height: 30px !important;
}
</style>
