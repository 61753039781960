<template>
  <div>
    <el-form :model="sysGroup" ref="groupForm" label-width="80px" class="demo-ruleForm" :size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item label="组名称">
            <el-input v-model="sysGroup.groupName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否启用">
            <el-select v-model="sysGroup.isEnabled" placeholder="是否锁定">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-button type="primary" icon="el-icon-search" :size="small" @click="findByPage">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" :size="small" @click="drawer.status=true;drawer.groupId=''">
          新增组信息
        </el-button>
        <!--<el-button type="primary" :size="small">主要按钮</el-button>
        <el-button type="success" :size="small">成功按钮</el-button>
        <el-button type="info" :size="small">信息按钮</el-button>
        <el-button type="warning" :size="small">警告按钮</el-button>
        <el-button type="danger" :size="small">危险按钮</el-button>-->
      </el-row>
    </el-form>

    <el-table
        :data="tableData"
        :height="tableHeight"
        border
        :size="small"
        style="width: 100%;margin-top: 10px;"
        v-loading="loading"
        ref="topictable"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="120">
      </el-table-column>
      <el-table-column
          prop="groupCode"
          label="组代码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="groupName"
          label="组名称"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="isEnabled"
          label="是否启用"
          :formatter="formatIsEnabled"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdBy"
          label="创建人"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="lastModifyTime"
          label="修改时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="lastModifyBy"
          label="修改人"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="groupDesc"
          label="描述"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200">
        <!--        <template slot-scope="scope">-->
        <template slot-scope="scope">
          <el-button type="text" @click="toEdit(scope.row)" :size="small">编辑</el-button>
          <el-button type="text" @click="transferGroupRole(scope.row.id)" :size="small">角色授权</el-button>
          <el-button type="text" @click="transferGroupUser(scope.row.id)" :size="small">人员授权</el-button>
          <!--          <el-button @click="drawer=true" type="text" :size="small">查看</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
        style="margin-top: 10px">
    </el-pagination>

    <el-drawer
        title=""
        :size="`90%`"
        :destroy-on-close="true"
        :visible.sync="drawer.status"
        :show-close="true"
        ref="drawer"
        :with-header="false">
      <AddGroup :drawer="drawer" v-if="drawer.groupId === ''" :findByPage="findByPage"/>
      <EditGroup v-else :drawer="drawer" :findByPage="findByPage"/>
    </el-drawer>

    <el-dialog
        :title="transfer.title"
        :destroy-on-close="true"
        :visible.sync="transfer.status"
        v-if="transfer.status"
        top="5vh"
        width="80%">
      <div class="el-dialog-div">
        <TransferGroupUser v-if="transfer.flag==='user'" :transfer="transfer" :findByPage="findByPage"/>
        <TransferGroupRole v-else :transfer="transfer" :findByPage="findByPage"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/utils/request'
import AddGroup from "@/views/group/AddGroup";
import EditGroup from "@/views/group/EditGroup";
import TransferGroupUser from "@/views/group/TransferGroupUser";
import TransferGroupRole from "@/views/group/TransferGroupRole";

export default {
  components: {
    AddGroup,
    EditGroup,
    TransferGroupUser,
    TransferGroupRole
  },
  data() {
    return {
      small: 'small',
      drawer: {
        status: false,
        groupId: '',
        groupData: ''
      },
      transfer: {
        status: false,
        groupId: '',
        flag: '',
        title: ''
      },
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      loading: true,
      pageTotal: 0,
      tableHeight: null,
      sysGroup: {
        groupName: '',
        isEnabled: ''
      },
    }
  },

  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findByPage()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findByPage()
    },
    onSubmit() {
      this.findByPage();
    },
    formatIsEnabled: function (row) {
      return row.isEnabled === 1 ? '是' : row.isEnabled === 0 ? '否' : '未知'
    },
    findByPage() {
      this.loading = true

      return request({
        url: `/masterdata/sysGroup/findByPage/${this.currentPage}/${this.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: this.sysGroup
      }).then((response) => {
        this.tableData = response.data.content;
        this.pageTotal = response.data.totalElements;
        this.loading = false;
      })
    },
    transferGroupUser(id) {
      this.transfer.groupId = id
      this.transfer.status = true
      this.transfer.flag = 'user'
      this.transfer.title = '人员授权'
    },
    transferGroupRole(id) {
      this.transfer.groupId = id
      this.transfer.status = true
      this.transfer.flag = 'role'
      this.transfer.title = '角色授权'
    },
    toEdit(row) {
      this.drawer.status = true;
      this.drawer.groupId = row.id;
      this.drawer.groupData = row
    }
  }, mounted() {
    this.findByPage()
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 80;
  },
}
</script>

<style>
.el-dialog-div {
  height: 61.5vh;
  overflow: auto;
}

.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
</style>
